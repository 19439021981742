import React, { useState } from "react";
import logo from "../../assets/img/logo.png";
import profile from "../../assets/img/profile.png";
import { Link } from "react-router-dom";
import ManageOrder from "../../assets/img/manage-order.png";
import "./CustomerProfile.css";
import Header from "../header/Header";
import { AiOutlineHome } from "react-icons/ai";

const Sidebar = () => {
  const [isOpen, setIsOpen] = useState(false);

  const toggleSidebar = () => {
    setIsOpen(!isOpen);
  };

  return (
    <nav className={`sidebar ${isOpen ? "open" : "close"}`}>
      <header>
        <div className="image-text">
          <div className="text logo-text">
            <Link to="/">
              <img src={logo} alt="Logo" />
            </Link>
          </div>
        </div>
        <i
          className={`bx ${isOpen ? "bx-x" : "bx-menu"} toggle`}
          onClick={toggleSidebar}
        />
      </header>
      <div className="menu-bar">
        <div className="menu">
          <li className="overview-text">
            <span className="text nav-text">OverView</span>
          </li>
          <ul className="menu-links">
            <li className="nav-link">
              <Link to="/">
                <AiOutlineHome
                  fontSize="28px"
                  color="white"
                  style={{ marginLeft: "10px" }}
                />
                <span className="text nav-text">Home</span>
              </Link>
            </li>
            <li className="nav-link">
              <Link to="/admin/profile">
                <img src={profile} alt="Profile" />
                <span className="text nav-text">Profile</span>
              </Link>
            </li>
            <li className="nav-link">
              <Link to="/admin/orderList">
                <img src={ManageOrder} alt="Manage Order" />
                <span className="text nav-text">Order List</span>
              </Link>
            </li>
            <li className="nav-link">
              <Link to="/admin/recentOrder">
                <img src={ManageOrder} alt="Manage Order" />
                <span className="text nav-text">Recent Orders</span>
              </Link>
            </li>
          </ul>
        </div>
      </div>
    </nav>
  );
};

export default Sidebar;
